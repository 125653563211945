import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { shippingApi, shippingFileFactory } from "./calls";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey, pluralize, queryString } from "utilities";
import { Courier, CreateCourier, Shipment, ShipmentListItem, ShippingPiece } from "./models";
import { FormikHelpers } from "formik";
import { shippingKeys } from "./keys";
import { createApiQuery } from "hooks/createApiQuery";
import { parsePatchData } from "utilities/parsePatchData";
import { PartialOf } from "typeUtilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Pagination, UUID } from "api/types";
import produce from "immer";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { useRedux, useSelector, useToastr } from "hooks";
import { fileDownloader } from "fileDownloader";
import { ShippingService } from "constants/shippingServiceConstants";
import { getDefaultShippingServiceFieldsFromPartials } from "pages/logistics/shared/getDefaultFieldsFromPartials";
import { FailedResponse } from "components/miloDesignSystem/organisms/failedResponseModal/FailedResponseModal";
import { openShipmentCreationSummary } from "components/common/shipmentsSection/openShipmentCreationSummary";

const useShippingCouriers = createPaginatedApiQuery(shippingApi.getShippingCouriers);
const useShippingCourier = createApiQuery(shippingApi.getShippingCourier);
const useSendToExternal = createApiQuery(shippingApi.getSendShipmentsToExternal);

const useShippingShipments = createPaginatedApiQuery(shippingApi.getShippingShipments);
const useShippingShipment = createApiQuery(shippingApi.getShippingShipment);
const useShippingPieces = createPaginatedApiQuery(shippingApi.getShippingPieces);

const usePostCourier = (close: () => void) => {
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  const createCourier = useMutation(shippingApi.postShippingCourier, ({ toastr, queryClient }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(shippingKeys.couriers.list());
      refetchPartials();
      close();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Dodano kuriera",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

  const handleSubmit = (values: CreateCourier, actions: FormikHelpers<CreateCourier>) => {
    createCourier.mutate(values, {
      onSuccess: () => {
        actions.setSubmitting(false);
      },
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return handleSubmit;
};

const useDeleteShippingShipment = () => {
  return useMutation(shippingApi.deleteShippingShipment, ({ toastr, queryClient }) => ({
    onSuccess: payload => {
      queryClient.invalidateQueries();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePostShippingPiece = () => {
  return useMutation(shippingApi.postShippingPiece, ({ toastr, queryClient }) => ({
    onSuccess: () => queryClient.invalidateQueries(shippingKeys.pieces()),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePostAdvanceShipmentNotification = () => {
  return useMutation(
    shippingApi.postAdvanceShippingNotification,
    ({ queryUtils }) => queryUtils.invalidateOnSuccessAndHandleError,
  );
};

export const useCourierPatchMutation = () => {
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());
  return useMutation(
    ({ id, toUpdate }: { id: number; toUpdate: PartialOf<Courier> }) => {
      return shippingApi.patchShippingCourier(parsePatchData(toUpdate), id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevPanel = queryUtils.handleMutate(shippingKeys.couriers.details(id), toUpdate);
        const prevList = queryUtils.handlePaginatedListUpdate(
          shippingKeys.couriers.list(),
          id,
          toUpdate,
        );
        return { prevList, prevPanel };
      },
      onSuccess: () => {
        refetchPartials();
      },
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(shippingKeys.couriers.details(id), onMutateReturn.prevPanel, error);
        queryUtils.rollbackList(shippingKeys.couriers.list(), onMutateReturn.prevList, id);
      },
    }),
  );
};

const usePatchShipping = () => {
  const getDefaultsToUpdate = usePatchShippingWithDefaults();
  const shippingServices = useSelector(state => state.partials.shippingShippingServices);
  const patchMultipleShippingPiecesMutation = usePatchMultipleShippingPieces();
  return useMutation(
    ({ id, toUpdate: baseToUpdateFields }: { id: UUID; toUpdate: PartialOf<Shipment> }) => {
      const parsedData = parsePatchData(baseToUpdateFields);
      const defaults = getDefaultsToUpdate(baseToUpdateFields);
      const toUpdate = {
        ...defaults,
        ...parsedData,
      };
      return shippingApi.patchShippingShipment(toUpdate, id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate: baseToUpdateFields }) => {
        const defaults = getDefaultsToUpdate(baseToUpdateFields);
        const toUpdate = {
          ...defaults,
          ...baseToUpdateFields,
        };
        const prevPanel = queryUtils.handleMutate(shippingKeys.shipments.details(id), toUpdate);
        const prevList = queryUtils.handlePaginatedListUpdate(
          shippingKeys.shipments.list(),
          id,
          toUpdate,
        );
        return { prevList, prevPanel };
      },
      onSuccess: payload => {
        if (payload.shippingService?.provider === ShippingService.AMBRO) {
          const shippingService = shippingServices.find(
            ({ id }) => id === payload.shippingService?.id,
          );
          assertIsDefined(shippingService);
          patchMultipleShippingPiecesMutation.mutate({
            shipments: [payload.id],
            ambroPackageType: shippingService.ambroDefaultPackageType,
          });
          return;
        }
      },
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(shippingKeys.shipments.details(id), onMutateReturn.prevPanel, error);
        queryUtils.rollbackList(shippingKeys.shipments.list(), onMutateReturn.prevList, id);
      },
    }),
  );
};

const useDeleteShippingPiece = () => {
  return useMutation(shippingApi.deleteShippingPiece, ({ queryClient }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  }));
};

const usePatchShippingPiece = () => {
  return useMutation(
    ({ id, toUpdate }: { id: UUID; toUpdate: PartialOf<ShippingPiece> }) => {
      return shippingApi.patchShippingPiece(parsePatchData(toUpdate), id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevList = queryUtils.handlePaginatedListUpdate(shippingKeys.pieces(), id, toUpdate);
        return { prevList };
      },
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollbackList(shippingKeys.pieces(), onMutateReturn.prevList, id, error);
      },
    }),
  );
};

const usePatchMultipleShippingPieces = () => {
  return useMutation(
    shippingApi.patchMultipleShippingPieces,
    ({ queryUtils, queryClient, toastr }) => ({
      onMutate: ({ product, shipments, ...toUpdate }) => {
        const key = shippingKeys.pieces(queryString.stringify({ shipments }));
        const prevList = queryClient.getQueryData(key);
        if (!prevList) return { prevList: undefined };

        queryClient.setQueryData<Pagination<ShippingPiece>>(key, prev => {
          assertIsDefined(prev);
          return produce(prev, draft => {
            const piecesToUpdate = (() => {
              if (product) {
                return draft!.results.filter(piece => piece.productId === product);
              }
              return draft.results;
            })();

            piecesToUpdate.forEach(piece => {
              Object.assign(piece, { ...toUpdate });
            });
          });
        });
        return { prevList };
      },
      onError: (error, { shipments }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        if (!onMutateReturn.prevList) {
          toastr.open({
            type: "warning",
            title: "Wymagane działanie",
            text: getAnyErrorKey(error),
          });
          return;
        }
        queryUtils.rollback(
          shippingKeys.pieces(queryString.stringify({ shipments })),
          onMutateReturn.prevList,
          error,
        );
      },
    }),
  );
};

const useSendShipmentsToExternal = () => {
  return useMutation(shippingApi.sendShipmentsToExternal, ({ toastr, queryClient }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      if (error.response?.status === 500) {
        toastr.open({
          type: "failure",
          title: "Nie udało się zlecić przesyłek",
          text: getAnyErrorKey(error),
        });
      }
    },
  }));
};

const useCancelShipments = () => {
  return useMutation(shippingApi.postCancelShipments, ({ toastr, queryClient, queryUtils }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: (error, { shipments }) => {
      toastr.open({
        type: error.response?.status === 500 ? "failure" : "warning",
        title: `Nie udało się anulować ${pluralize.pl(shipments.length, {
          singular: "przesyłki",
          plural: "przesyłek",
          other: "przesyłek",
        })}`,
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useDownloadShippingLabel = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const toastr = useToastr();
  const handleDownloadLabel = async (shipmentId: UUID) => {
    const downloadToastr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = shippingFileFactory.shippingLabels([shipmentId]);

    const response = await fileDownloader({
      onProgress: downloadToastr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      downloadToastr.lazyClose();
    } else {
      downloadToastr.close();
      toastr.open({
        type: response.httpStatus === 400 ? "warning" : "failure",
        title: response.httpStatus === 400 ? "Wymagane działanie" : "Oj, coś nie tak.",
        text: getAnyErrorKey(response.error),
      });
    }
  };

  return async (row: ShipmentListItem) => {
    if (!row.shippingService) return;
    if (
      row.shippingService?.provider === ShippingService.MEBEL_TAXI ||
      row.shippingService?.provider === ShippingService.DM_TRANS
    ) {
      const data = await shippingApi.getRedirectShipmentServiceURL(row.id, row.shippingService!.id);
      window.open(data.url, "_blank");
      return;
    }
    handleDownloadLabel(row.id);
  };
};

const useDownloadShippingLabels = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const toastr = useToastr();
  const handleDownloadLabels = async (shipments: UUID[]) => {
    const downloadToastr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = shippingFileFactory.shippingLabels(shipments);

    const response = await fileDownloader({
      onProgress: downloadToastr.updateProgress,
      url,
      name,
    });

    if (response.status === "success") {
      downloadToastr.lazyClose();
    } else {
      downloadToastr.close();
      toastr.open({
        type: response.httpStatus === 400 ? "warning" : "failure",
        title: response.httpStatus === 400 ? "Wymagane działanie" : "Oj, coś nie tak.",
        text: getAnyErrorKey(response.error),
      });
    }
  };

  return async (shipments: UUID[]) => {
    handleDownloadLabels(shipments);
  };
};

const useAssignShipmentProvider = () =>
  useMutation(
    shippingApi.postAssignShipmentProvider,
    ({ queryUtils }) => queryUtils.invalidateOnSuccessAndHandleError,
  );

const usePatchShippingShipments = () => {
  const shippingServices = useSelector(state => state.partials.shippingShippingServices);
  const getDefaultsToUpdate = usePatchShippingWithDefaults();
  const patchMultipleShippingPiecesMutation = usePatchMultipleShippingPieces();
  return useMutation(
    (toUpdate: Parameters<typeof shippingApi.patchShippingShipments>[0]) => {
      const parsedData = parsePatchData(toUpdate);
      const defaults = getDefaultsToUpdate(toUpdate);
      return shippingApi.patchShippingShipments({
        ...parsedData,
        ...defaults,
        shipments: toUpdate.shipments,
      });
    },
    ({ queryClient, toastr }) => ({
      onSuccess: (_, variables) => {
        if (variables.shippingService?.provider === ShippingService.AMBRO) {
          const shippingService = shippingServices.find(
            ({ id }) => id === variables.shippingService?.id,
          );
          assertIsDefined(shippingService);
          patchMultipleShippingPiecesMutation.mutate({
            shipments: variables.shipments,
            ambroPackageType: shippingService.ambroDefaultPackageType,
          });
        }
        queryClient.invalidateQueries();
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const usePostInternalShipment = () =>
  useMutation(shippingApi.postInternalShipment, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

const usePostShippingAuthorize = () =>
  useMutation(shippingApi.postShippingAuthorize, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

const usePostShippingCheckConnection = () =>
  useMutation(shippingApi.getShippingCheckConnection, ({ toastr, queryClient }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();

      toastr.open({
        type: "success",
        title: "Połączenie z kurierem jest aktywne",
        text: "",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Brak połączenia",
        text: getAnyErrorKey(error),
      });
    },
  }));

const useCreateShipments = () => {
  return useMutation(
    ({ orders }: { orders: number[]; open: (stateToSet: FailedResponse) => void }) => {
      return shippingApi.postInternalShipments({ orders });
    },
    ({ queryClient, toastr }) => ({
      onSuccess: (payload, args) => {
        openShipmentCreationSummary(payload, args.open);
        queryClient.invalidateQueries();
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

export const shippingActions = {
  useShippingShipments,
  useShippingCouriers,
  useShippingCourier,
  usePostCourier,
  useCourierPatchMutation,
  useShippingShipment,
  usePatchShipping,
  useShippingPieces,
  usePatchMultipleShippingPieces,
  usePatchShippingPiece,
  useSendShipmentsToExternal,
  useDownloadShippingLabel,
  usePostShippingPiece,
  usePatchShippingShipments,
  useSendToExternal,
  useDeleteShippingPiece,
  useDownloadShippingLabels,
  useCancelShipments,
  usePostInternalShipment,
  usePostShippingAuthorize,
  usePostShippingCheckConnection,
  useDeleteShippingShipment,
  useCreateShipments,
  useAssignShipmentProvider,
  usePostAdvanceShipmentNotification,
};

function usePatchShippingWithDefaults() {
  const shippingServices = useSelector(state => state.partials.shippingShippingServices);

  return (toUpdate: Partial<Shipment>) => {
    if (Object.keys(toUpdate).includes("shippingService")) {
      const shippingService = shippingServices.find(
        service => service.id === toUpdate.shippingService!.id,
      );
      assertIsDefined(shippingService);
      const defaults = getDefaultShippingServiceFieldsFromPartials(shippingService);
      return defaults;
    }
    return toUpdate;
  };
}
