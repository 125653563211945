import { memo } from "react";
import { getPagination, noop } from "utilities";
import { StateProxy } from "components/utils";
import { useQuery, useThresholdToggle } from "hooks";
import prevIcon from "assets/images/27.svg";
import nextIcon from "assets/images/28.svg";
import { Button } from "components/common/buttonLegacy";
import styles from "./Pagination.module.css";
import cx from "classnames";
import { Spinner } from "../spinner";

interface Props {
  pagination: ReturnType<typeof getPagination>;
  onChange?: (page: number) => void;
  mode?: "auto" | "manual";
  page?: number;
  sticky?: boolean;
  isFetching?: boolean;
  removeMarginLeft?: boolean;
}

export const Pagination = memo(
  ({
    pagination,
    onChange = noop,
    mode = "auto",
    page,
    sticky = false,
    isFetching = false,
    removeMarginLeft = false,
  }: Props) => {
    const { query, setQuery } = useQuery();
    const { isOpen: isNavbarOpen } = useThresholdToggle();
    const { limit, count, pageSize } = pagination;
    const debounceRequest = Number(page || query.page || 1) !== pagination.page;

    const handlePage = (page: number) => {
      if (mode === "auto") {
        setQuery({ ...query, page });
      }
      onChange(page);
    };

    if (!pagination.count) {
      return null;
    }

    return (
      <div
        className={cx(
          "d-flex align-items-center p-2",
          isNavbarOpen ? styles.basePadding : styles.collapsedPadding,
          {
            [styles.pagination]: !sticky,
            [styles.stickyPagination]: sticky,
            [styles.removeMarginLeft]: removeMarginLeft,
          },
        )}
      >
        <StateProxy
          state={page || Number(query.page) || 1}
          debounce={debounceRequest ? 1000 : undefined}
          onChange={handlePage}
        >
          {({ state, setState }) => (
            <>
              <div className={cx("d-flex align-items-center ml-2", styles.pages)}>
                <div className="position-absolute">
                  <Spinner
                    overrides={{ boxSpinner: { className: styles.boxSpinner } }}
                    on={isFetching}
                    color="blue"
                    size="small"
                    position="absolute"
                    style={{ height: "0", top: 0, left: "-30px" }}
                  />
                </div>
                <span className="d-inline-flex mr-2">Wyniki</span>
                {!pageSize || !count ? (
                  "--"
                ) : (
                  <strong>
                    {state * pageSize - pageSize + 1}-
                    {state * pageSize > count ? count : state * pageSize}
                  </strong>
                )}
                <span>/{count}</span>
              </div>
              <div className="d-flex align-items-center ml-2">
                <Button
                  type="button"
                  kind="secondary"
                  size="round-s"
                  onClick={() => (state < 2 ? null : setState(s => s - 1))}
                  disabled={state <= 1}
                  className={styles.button}
                >
                  <img src={prevIcon} alt="Poprzednia" />
                </Button>

                <Button
                  type="button"
                  kind="secondary"
                  size="round-s"
                  onClick={() => (!limit || state >= limit ? null : setState(s => s + 1))}
                  disabled={!limit || state >= limit}
                  className={styles.button}
                >
                  <img src={nextIcon} alt="Następna" />
                </Button>
              </div>
            </>
          )}
        </StateProxy>
      </div>
    );
  },
);
